<template lang="pug">
.more-or-less
  .more-or-less-content(ref="expandable" :class="contentClasses" :style="{ gap: gapSize }")
    slot(v-if="expanded")
  .more-or-less-bottom.d-flex.justify-content-center(v-if="!hideToggler && !fix")
    .more-or-less-toggler.d-flex.cursor-pointer.align-items-center(
      @click="toggle"
      :class="{ 'mt-3': expanded }"
      :data-block-name="blockName"
      :data-pane-name="paneName"
    )
      component(:is="iconName" size="2em")
      .more-or-less-bottom-text {{ expanded ? $t('lessOptions') : $t('moreOptions') }}
</template>

<script>
  import { mapState } from 'vuex';
  import { UilAngleDown, UilAngleUp } from '@iconscout/vue-unicons';
  import expandMixin from '@/components/Editor/mixins/expand';

  export default {
    name: 'MoreOrLess',
    components: { UilAngleDown, UilAngleUp },
    mixins: [expandMixin],
    props: {
      parentExpanded: {
        type: Boolean,
        default: null,
      },
      hideToggler: {
        type: Boolean,
        default: false,
      },
      gapSize: {
        type: String,
        default: '12px',
      },
    },
    data: () => ({ expanded: false, blockName: null, paneName: null }),
    computed: {
      ...mapState(['selectedElement']),
      iconName() {
        return this.expanded ? 'uil-angle-up' : 'uil-angle-down';
      },
      contentClasses() {
        if (this.fix) return 'h-100';
        return '';
      },
    },
    mounted() {
      if (this.fix || this.open) this.expanded = true;

      this.$nextTick(() => {
        this.blockName = this.$el
          .closest('.editor-accordion')
          ?.querySelector('.editor-heading-title')?.textContent;
        this.paneName = this.$el.closest('.ds-pane')?.querySelector('.ds-pane-title')?.textContent;
      });
    },
    methods: {
      toggle() {
        if (this.fix) return;
        this.expanded = !this.expanded;
        this.$emit('toggle', this.expanded);
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .more-or-less
    color: $om-dark-grey-3
    &-content
      transition: opacity 150ms ease-out
      display: grid
      &.h-100
        opacity: 1
</style>
